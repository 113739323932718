import { base_url } from './ConstantUrl';

export function fetchAllProfilesUrl() {
	return base_url + '/api/admin/fetch_all_profiles';
}

export function fetchCollegesByNameUrl(name) {
	return base_url + `/api/admin/get_all_colleges?name=${name}`;
}

export function getMentorForOrgUrl() {
  return base_url + `/api/admin/get_list_of_mentors_for_org`;
}
export function fetchDegreesByNameUrl(name) {
	return base_url + `/api/admin/get_all_degrees?name=${name}`;
}

export function fetchSpecializationByNameUrl(name) {
	return base_url + `/api/admin/get_all_specializations?name=${name}`;
}

export function fetchDataGroupsByNameUrl(name) {
	return base_url + `/api/admin/get_all_data_groups?name=${name}`;
}

export function fetchAllQuestBatchesUrl() {
	return base_url + '/api/admin/get_all_quest_batches';
}

export function fetchQuestsByNameUrl(name, published = true) {
	return base_url + `/api/admin/get_all_quests?name=${name}&published=${published}`;
}
export function addMentorToBatchUrl(title) {
  return base_url + `/api/admin/enroll_college_batch_by_emails`;
}

export function getAllQuestForAuthor(title) {
  return base_url + `/api/admin/get_all_quests_for_author?name=${title}`;
}



// export function addMentorToBatchUrl(title) {
//   return base_url + `/api/admin/create_multiple_my_data_groups_by_filters`;
// }

export function fetchProjectsByNameUrl(name, published = true) {
	return base_url + `/api/admin/get_all_projects?name=${name}&published=${published}`;
}

export function fetchAllProjectsUrl() {
  return base_url + `/api/admin/get_all_projects`;
}

export function fetchResourceGroupsByNameUrl(name, published = true) {
	return base_url + `/api/admin/get_all_resource_groups?name=${name}&published=${published}`;
}

export function fetchQuizzesByNameUrl(name, published = true) {
	return base_url + `/api/admin/get_all_quizzes?name=${name}&published=${published}`;
}
export function createBatchByProfessorUrl(type) {
	return base_url + `/api/admin/create_batch_by_professor?type=${type}`;
}
export function batchStudentStatusUrl(type) {
	return base_url + `/api/admin/quiz_activity_student_status?type=${type}`;
}

export function fetchEventsByNameUrl(name) {
	return base_url + `/api/admin/get_all_events?name=${name}`;
}

export function fetchClubsByNameUrl(name) {
	return base_url + `/api/admin/get_all_clubs?name=${name}`;
}

export function fetchJobsByNameUrl(name) {
	return base_url + `/api/admin/get_all_jobs?name=${name}`;
}

export function fetchAssignmentsByNameUrl(title, published = true) {
	return base_url + `/api/admin/get_all_assignments?title=${title}&published=${published}`;
}

export function fetchPeerAssignmentsByNameUrl(dg_id) {
	return base_url + `/api/get_all_peer_evaluation_assignments?data_group_id=${dg_id}`;
}

export function fetchPeerAssignmentStudentsUrl(activity_id,dg_id) {
	return base_url + `/api/admin/get_unenrolled_students_peer_evaluation?id=${activity_id}&data_group_id=${dg_id}`;
}

export function bulkEnrollQuestsUrl() {
	return base_url + `/api/admin/bulk_quest_enrollments`;
}

export function createBatchDataGroupUrl() {
	return base_url + `/api/admin/create_batch_data_group`;
}

export function myQuestCleanupUrl() {
	return base_url + `/api/admin/my_quest_clean_up`;
}

export function fetchQuestDataGroupsUrl(quest_id) {
	return base_url + `/api/admin/quest_data_groups?quest_id=${quest_id}`;
}

export function bulkEnrollProjectsUrl() {
	return base_url + `/api/admin/bulk_project_enrollments`;
}

export function myProjectsCleanupUrl() {
	return base_url + `/api/admin/my_project_clean_up`;
}

export function updateMyProjectStatusUrl() {
	return base_url + `/api/admin/update_project_status`;
}

export function bulkEnrollResourceGroupsUrl() {
	return base_url + `/api/admin/bulk_enroll_resource_groups`;
}

export function myResourceGroupsCleanupUrl() {
	return base_url + `/api/admin/my_resource_group_clean_up`;
}

export function bulkEnrollQuizzesUrl() {
	return base_url + `/api/admin/bulk_enroll_to_quiz`;
}

export function bulkEnrollQuizBankUrl() {
	return base_url + `/api/admin/bulk_enroll_to_question_bank`;
}

export function myQuizzesCleanupUrl() {
	return base_url + `/api/admin/my_quizzes_clean_up`;
}

export function myActivityCleanupUrl() {
	return `${base_url}/api/admin/my_assignment_clean_up`;
}

export function generateReportUrl() {
	return base_url + `/api/admin/send_report`;
}

export function sendReportUrl() {
	return base_url + `/api/admin/filtered_student_data`;
}

export function fetchOrgBasedEventsUrl(org_id) {
	return base_url + `/api/admin/show_org_events?org_id=${org_id}`;
}

export function bulkEnrollNextUnitUrl() {
	return base_url + `/api/admin/bulk_enroll_to_next_unit`;
}

export function bulkEnrollEventsUrl() {
	return base_url + `/api/admin/bulk_enroll_to_events`;
}

export function updateEventStatusUrl() {
	return base_url + `/api/admin/update_event_status`;
}

export function myEventsCleanupUrl() {
	return base_url + `/api/admin/my_events_clean_up`;
}

export function myClubsCleanupUrl() {
  return `${base_url}/api/admin/my_clubs_clean_up`;
}

export function bulkEnrollClubsUrl() {
	return base_url + `/api/admin/bulk_enroll_to_clubs`;
}

export function bulkEnrollAssignmentsUrl() {
	return base_url + `/api/admin/bulk_enroll_to_assignment`;
}

export function updateClubStatusUrl() {
	return base_url + `/api/admin/update_club_status`;
}

export function updateJobStatusUrl() {
	return base_url + `/api/admin/update_job_status`;
}

export function createSkillsUrl() {
	return base_url + `/api/admin/create_skill`;
}

export function createOrgsUrl() {
	return base_url + `/api/admin/create_org`;
}

export function createDegreeUrl() {
	return base_url + `/api/admin/create_degree`;
}

export function createSubjectUrl() {
	return base_url + `/api/admin/create_lookup`;
}

export function createSpecializationUrl() {
	return base_url + `/api/admin/create_specialization`;
}

export function createJobRolesUrl() {
	return base_url + `/api/admin/create_job_role`;
}

export function fetchOrgByEmailUrl(keyword) {
	return base_url + `/api/admin/fetch_unapproved_profiles?keyword=${keyword}`;
}

export function fetchActiveStudentMentorProfileByEmailUrl(keyword) {
	return base_url + `/api/admin/fetch_active_profiles?keyword=${keyword}`;
}

export function fetchDeactiveStudentMentorProfileByEmailUrl(keyword) {
  return base_url + `/api/admin/fetch_deactive_profiles?keyword=${keyword}`;
}

export function approveOrgProfileUrl() {
	return base_url + '/api/approve_org_profile';
}

export function deactivateStudentMentorProfileUrl() {
	return base_url + '/api/deactive_profile';
}

export function activateStudentMentorProfileUrl() {
  return base_url + "/api/active_profile";
}

export function getAdminAccessLevelUrl() {
	return base_url + '/api/admin/get_admin_access_level';
}

export function getAdminAccessLevelV2Url() {
	return base_url + '/api/admin/get_admin_access_level_v2';
}

export function getQuestUnitReportUrl() {
	return base_url + `/api/admin/mentor_dashboard_status`;
}

export function getStudentStatus() {
	return base_url + `/api/admin/quiz_activity_student_status`;
}

export function getPlatformReports() {
	return `${base_url}/api/admin/entity_status_report`;
}

export function getEntityAuthoringDetail() {
  return `${base_url}/api/admin/fetch_entity_authoring_detail`;
}

export function getPlatformReportsByEmail() {
	return `${base_url}/api/admin/generate_platform_reports`;
}

export function resetUserPassword() {
	return `${base_url}/api/admin/reset_user_password`;
}

export function fetchUserPaymentPages() {
	return `${base_url}/api/get_my_payment_pages`;
}

export function fetchPaymentPagePayments(payment_page_id, status) {
	if (status) return `${base_url}/api/list/payment_page_payments?payment_page_id=${payment_page_id}&status=${status}`;
	else return `${base_url}/api/list/payment_page_payments?payment_page_id=${payment_page_id}`;
}

export function fetchDeactivateMyProject() {
	return `${base_url}/api/admin/deactivate_my_project`;
}

export function updateQuizScoreAccessUrl() {
	return `${base_url}/api/admin/update_quiz_score_access`;
}

export function reEvaluateQuizScoreUrl() {
	return `${base_url}/api/admin/re_evaluate_my_quiz_score`;
}

export function createEntityCopyUrl() {
	return `${base_url}/api/admin/create_entity_copy`;
}

export function fetchInstitutePermissionsUrl() {
	return `${base_url}/api/subscriptions`;
}

export function createSubscription() {
	return `${base_url}/api/admin/create_subscription`;
}

export function getSubscription(id) {
	return `${base_url}/api/subscriptions/${id}`;
}

export function updateSubscription() {
	return `${base_url}/api/admin/update_subscription`;
}

export function createAdminPermission() {
	return `${base_url}/api/admin/create_update_admin_access_level`;
}

export function createInterventionPermission() {
	return `${base_url}/api/admin/create_editing_intervention_access`;
}

export function lookupDetails() {
	return `${base_url}/api/admin/get_lookup`;
}

export function fetchinterventionDetails(type, value) {
	return `${base_url}/api/admin/get_intervention_details?type=${type}&text=${value}`;
}

export function updateInterveintionAuthor() {
	return `${base_url}/api/admin/change_author_for_intervention`;
}

export function deleteLookup(id) {
	return `${base_url}/api/admin/delete_lookup?id=${id}`;
}

// College admin dashboard
export function getCollegeProfileCount() {
  return `${base_url}/api/admin/get_college_profile_counts`;
}

export function getFilteredCollegeProfiles() {
  return `${base_url}/api/admin/get_all_college_student_profile`;
}

export function getPortfolioScores() {
  return `${base_url}/api/admin/get_students_score`;
}
export function degreeSpecDetails() {
  return `${base_url}/api/admin/degree_spec_details`;
}
export function getStudentPortfolioReport() {
  return `${base_url}/api/admin/generate_student_portfolio`;
}

export function fetchCollegeEntities() {
  return `${base_url}/api/admin/get_college_entities`;
}

export function fetchCollegeEntityCounts() {
  return `${base_url}/api/admin/get_college_entities_counts`;
}

export function sendEntitiesReportUrl() {
  return `${base_url}/api/admin/college_content_report`;
}

export function collegeContentCopyUrl() {
  return `${base_url}/api/admin/create_copy_college_content `;
}

export function fetchCollegeBatches() {
  return `${base_url}/api/admin/get_college_batches`;
}

export function enrollCollegeBatchByEmail() {
  return `${base_url}/api/admin/enroll_college_batch_by_emails`;
}

export function enrollCollegeBatchByFilters() {
  return `${base_url}/api/admin/enroll_college_batch_by_filters`;
}

export function unenrollCollegeBatch() {
  return `${base_url}/api/admin/unenroll_college_batch`;
}

export function createCollegeBatch() {
  return `${base_url}/api/admin/create_college_batch`;
}
export function createBatchByProfessor() {
  return `${base_url}/api/admin/create_batch_by_professor`;
}
// "api/admin/create_batch_by_professor";

export function fetchCollegeBatchUrl() {
  return `${base_url}/api/admin/get_college_batch_profile`
}

export function collegeBatchReport() {
  return `${base_url}/api/admin/college_batch_report`
}

export function bulkEnrollSpecializationUrl() {
  return base_url + `/api/admin/enroll_to_specialization`;
}

export function bulkRemoveSpecializationUrl() {
  return base_url + `/api/admin/remove_from_specialization`;
}

export function job_report() {
	return `${base_url}/api/admin/job_report`;
  }


  export function updateSkillsUrl() {
	return base_url + `/api/admin/update_skill`;
}


export function checkSkillUrl(id) {
	return base_url + `/api/admin/check_skill_links?id=${id}`;
}

export function updateEntitiesMarks() {
	return base_url + `/api/admin/update_entities_marks`;
}

export function updateEntitiesStatus() {
	return base_url + `/api/admin/update_entities_status`;
}
export function getStudentEntities() {
	return base_url + `/api/admin/get_student_techops`;
}

export function getTechopsDetail() {
	return base_url + `/api/admin/get_techops_details`;
}

